import React from "react";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import GoogleMapElement from "./GoogleMapElement";


const MapComponent = (props) => 
{
    
    

    return (
        <GridItem >
            <Card>
                 <CardBody>
                  
                              <GoogleMapElement />
                 </CardBody>
            </Card>
        </GridItem>
    )
}




export default MapComponent
