/*eslint-disable*/
import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";


import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { addSimCardAction, upadateSimCardAction } from "action/simCardAction";

// style for this view
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { useHistory } from "react-router";
import { connect } from "react-redux";

import * as service from '../../services/simCardService'
import * as notif from "components/Notification/notification"
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

const useStyles = makeStyles(styles);
const useStylesExtended = makeStyles(stylesExtended);


const SimCardUpdate = (props) => 
{

  //const history = useNavigate();
  //const history = useNavigate();
  const [paramId, setParamId] = useState()     
  
  const [openNotif, setOpenNotif] = useState(false)

  // type validation
  const [telephoneNum, settelephoneNum] = React.useState("");
  const [telephoneNumState, settelephoneNumState] = React.useState("");

  const [dateReception, setdateReception] = React.useState("");
  const [dateReceptionState, setdateReceptionState] = React.useState("");

  const [numeroCommand, setnumeroCommand] = React.useState("");
  const [numeroCommandState, setnumeroCommandState] = React.useState("");

  const [numeroSerie, setnumeroSerie] = React.useState("");
  const [numeroSerieState, setnumeroSerieState] = React.useState("");

  const [activation, setactivation] = React.useState("");
  const [activationState, setactivationState] = React.useState("");

  const [activeSimSelect, setActiveSimSelect] = React.useState(false);


 
 
    
    const  paramURLID   = useParams().id;
     
    useEffect(() => {
      setParamId(paramURLID)
    }, [])

    const handleActiveSim = event => 
    {
      setActiveSimSelect(event.target.value);
    };

    useEffect(()=> {

      if (openNotif === true)
      {
        if (props.loadSimCardFromSaga.loading === false)
        {
          let msg;
            let title;
            
          if (props.loadSimCardFromSaga.error.length > 0)
          {
            if (paramId)
            {
              title = "Echeque Mise a jour Sim"
              msg = "Mise a jours Sim echoué";
            }
            else
            {
              title = "Echeque ajout Sim"
              msg = "La Sim " + numeroSerie + " n a pas ete ajouter";
            }
            notif.showFailedNotification(title, msg)
            
          }
          else
          {
            if (paramId)
            {
              title = "Mise a jour Sim"
              msg = "La Sim " + numeroSerie + "  a ete mise a jours";
            }
            else
            {
              title = "Ajout  Sim"
              msg = "La Sim " + numeroSerie + " a ete ajouter";
            }
            notif.showSuccessNotification(title, msg)
          }
          setOpenNotif(false)
      }
    }
      
    }, [props.loadSimCardFromSaga])


    

    useEffect(() => 
    {
      console.log("PARAM ID => ", paramId)
      if (paramId)
      {
       // setParamId(paramId)
        const data = {
          'numeroDeSerieSim' : paramId
        }

        
        

        service.getItemCarteSim(data).then(
          (res) => {
            settelephoneNum(res.numeroCarteSim)
            if (verifyLength(res.numeroCarteSim, 0))
            {
              settelephoneNumState("success");
            }
            
            setdateReception(res.dateReceptionSim)
            if (verifyLength(res.dateReceptionSim, 0))
            {
              setdateReceptionState("success");
            }
            setnumeroCommand(res.numeroDeCommandeSim)
            if (verifyLength(res.numeroDeCommandeSim, 0))
            {
              setnumeroCommandState("success");
            }
            setnumeroSerie(res.numeroDeSerieSim)
            if (verifyLength(res.numeroDeSerieSim, 0))
            {
              setnumeroSerieState("success");
            }
            setactivation(res.active)
            setactivationState("success");

          }).catch(
            (error) => {
              //console.log('error = ',error)
            }
          )
      }
      
    }, [paramId])
       
   

       
        const successDelete = () => {
            setAlert(
              <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Deleted!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
              >
                Your imaginary file has been deleted.
              </SweetAlert>
            );
          };
          const cancelDetele = () => {
            setAlert(
              <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Cancelled"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
              >
                Your imaginary file is safe :)
              </SweetAlert>
            );
          };
          const hideAlert = () => {
            setAlert(null);
          };
        
        // function that returns true if value is email, false otherwise
        const verifyEmail = value => {
          var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (emailRex.test(value)) {
            return true;
          }
          return false;
        };
      
        
        // function that verifies if a string has a given length or not
        const verifyLength = (value, length) => {
          if (value.length > length) {
            return true;
          }
          return false;
        };
        // function that verifies if value contains only numbers
        const verifyNumber = value => {
          var numberRex = new RegExp("^[0-9]+$");
          if (numberRex.test(value)) {
            return true;
          }
          return false;
        };
       
        
        const typeClick = () => 
        {
            let error = false;

          if (telephoneNum === "") {
            settelephoneNumState("error");
            error = true;
          }

          if (dateReception === "") 
          {
            error = true;
            setdateReceptionState("error");
          }

          if (numeroCommand === "") 
          {
            error = true;
            setnumeroCommandState("error");
          }

          if (numeroSerie === "") 
          {
            error = true;
            setnumeroSerieState("error");
          }

          if (activeSimSelect === "") 
          {
            error = true;
            
          }

          const data = {
            "etatSim": 1,
            "numeroCarteSim": telephoneNum,
            "numeroDeCommandeSim": numeroCommand,
            "numeroDeSerieSim": numeroSerie,
            "dateReceptionSim": dateReception,
            "delete": false,
            "active": activeSimSelect
          }

          if (error === false)
          {
            
            setOpenNotif(true)
            if (paramId)
            {
              props.simCardUpdateDispatch(data);
              
            }
            else
            {
              props.simCardAddDispatch(data);
              
              
            }
            
            
            //history('/')
            
          }
          
        };


        const classes = useStyles();
        const classesExtended = useStylesExtended();

        
        return (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" text>
                  <CardText color="info">
                    <h4 className={classes.cardTitle}>Type Validation</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          telephoneNum
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={7}>
                        <CustomInput
                          success={telephoneNumState === "success"}
                          error={telephoneNumState === "error"}
                          id="telephoneNum"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {

                              
                              if (verifyLength(event.target.value, 0)) 
                              {
                                settelephoneNumState("success");
                              } else {
                                settelephoneNumState("error");
                              }
                              settelephoneNum(event.target.value);
                            },
                            value: telephoneNum,

                            type: "text",
                            endAdornment:
                              telephoneNumState === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelLeftHorizontal}>
                          <code>telephoneNum</code>
                        </FormLabel>
                      </GridItem>
                    </GridContainer>
                    

                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          dateReception
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={7}>
                        <CustomInput
                          success={dateReceptionState === "success"}
                          error={dateReceptionState === "error"}
                          id="dateReception"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              if (verifyLength(event.target.value, 0)) {
                                setdateReceptionState("success");
                              } else {
                                setdateReceptionState("error");
                              }
                              setdateReception(event.target.value);
                            },
                            value: dateReception,
                            type: "text",
                            endAdornment:
                              dateReceptionState === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelLeftHorizontal}>
                          <code>dateReception</code>
                        </FormLabel>
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Numero commande
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={7}>
                        <CustomInput
                          success={numeroCommandState === "success"}
                          error={numeroCommandState === "error"}
                          id="numeroCommand"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              if (verifyLength(event.target.value, 0)) {
                                setnumeroCommandState("success");
                              } else {
                                setnumeroCommandState("error");
                              }
                              setnumeroCommand(event.target.value);
                            },
                            type: "text",
                            value: numeroCommand,
                            endAdornment:
                              numeroCommandState === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelLeftHorizontal}>
                          <code>numeroCommand</code>
                        </FormLabel>
                      </GridItem>
                    </GridContainer>


                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Numero de Serie
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={7}>
                        <CustomInput
                          success={numeroSerieState === "success"}
                          error={numeroSerieState === "error"}
                          id="numeroSerie"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              if (verifyLength(event.target.value, 0)) {
                                setnumeroSerieState("success");
                              } else {
                                setnumeroSerieState("error");
                              }
                              if (!paramId)
                              setnumeroSerie(event.target.value);
                            },
                            type: "text",
                            value: numeroSerie,
                            endAdornment:
                              numeroSerieState === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelLeftHorizontal}>
                          <code>numeroSerie</code>
                        </FormLabel>
                      </GridItem>
                    </GridContainer>


                    



                  <GridContainer>
                  <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          activation
                        </FormLabel>
                      </GridItem>

                    <GridItem xs={12} sm={7} >
                   

                      <FormControl
                        fullWidth
                        className={classesExtended.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classesExtended.selectLabel}
                        >
                          
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classesExtended.selectMenu
                          }}
                          classes={{
                            select: classesExtended.select
                          }}
                          value={activeSimSelect}
                          onChange={handleActiveSim}
                          inputProps={{
                            name: "simpleSelect",
                            id: "simple-select"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classesExtended.selectMenuItem
                            }}
                          >
                            Activation
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classesExtended.selectMenuItem,
                              selected: classesExtended.selectMenuItemSelected
                            }}
                            value={false}
                          >
                            Desactiver
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classesExtended.selectMenuItem,
                              selected: classesExtended.selectMenuItemSelected
                            }}
                            value={true}
                          >
                            Active
                          </MenuItem>
                          
                          
                        </Select>
                      </FormControl>
                    </GridItem>

                  </GridContainer>
                    
                  </form>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button color="info" onClick={typeClick}>
                    Validate Inputs
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            
          </GridContainer>
        );
      
}


const mapStateToProps = (state) => {
  
  return (
      {
        loadSimCardFromSaga : state.simCards
      }
    )
  }

const mapsDispatchToProps = dispatch => {
    
    return (
      {
        simCardAddDispatch: (data) => {dispatch(addSimCardAction(data))},
        simCardUpdateDispatch: (data) => {dispatch(upadateSimCardAction(data))}
    })
  }


export default connect(mapStateToProps, mapsDispatchToProps)(SimCardUpdate);